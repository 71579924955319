import React from "react";

function Home() {
    return (
      <div>
        <p>
        <h3>Hello!</h3>
        I'm Andrew, and I'm interested in the intersection between maps, data, and web applications.
        </p>
      </div>
    );
  }
  
  export default Home;